<template>
  <div>
    <b-link :to="{ name: 'addPermission' }">Ajouter une permission</b-link>
    <b-row>
      <b-col>
        <b-form-input v-model="searchedName" placeholder="Rechercher"/>
      </b-col>
    </b-row>
    <b-row class="my-2">
      <b-col>
        <b-skeleton-wrapper :loading="loading">
          <template #loading>
            <PermissionListSkeleton/>
          </template>
          <PermissionList :permissions="filteredPermissions"/>
        </b-skeleton-wrapper>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {permissionToString} from "@/functions";
import {mapState} from "vuex";
import PermissionList from "@/components/permissions/PermissionList";
import PermissionListSkeleton from "@/components/permissions/PermissionListSkeleton";

export default {
  components: {
    PermissionList,
    PermissionListSkeleton,
  },
  data() {
    return {
      loading: true,
      searchedName: null,
    };
  },
  computed: {
    ...mapState({
      permissions: (state) => state.permissions.all,
    }),
    filteredPermissions() {
      if (!this.searchedName) {
        return this.permissions;
      }

      const search = this.searchedName.toLowerCase();
      return this.permissions.filter(
          (permission) => permissionToString(permission).indexOf(search) !== -1
      );
    },
  },
  async created() {
    try {
      await this.$store.dispatch("permissions/fetchPermissions");
    } catch (e) {
      await this.$swal.fire({
        icon: "error",
        title:
            "Une erreur est survenue lors de la récupération des permissions",
        text: e.message,
      });
      return;
    }
    this.loading = false;
  },
};
</script>

