<template>
  <b-table
      hover
      striped
      borderless
      head-variant="light"
      :items="sortedPermissions"
      :fields="fields"
  >
    <template #cell(name)="permission">
      {{ permissionToString(permission.item) }}
    </template>
    <template #cell(edit)="permission">
      <div class="row justify-content-end mr-0">
        <EditDeleteButtonGroup
            @edit="editPermission(permission.item)"
            @delete="deletePermission(permission.item)"
        />
      </div>
    </template>
  </b-table>
</template>

<script>
import {permissionToString} from "@/functions"
import EditDeleteButtonGroup from "@/components/buttons/EditDeleteButtonGroup";

export default {
  data() {
    return {
      fields: [
        {key: "name", label: "Nom"},
        {key: "edit", label: ""},
      ],
    };
  },
  props: {
    permissions: {
      required: true,
      type: Array,
    },
  },
  computed: {
    sortedPermissions() {
      return this.permissions
          .slice()
          .sort((a, b) =>
              permissionToString(a).toLowerCase() >
              permissionToString(b).toLowerCase()
                  ? 1
                  : -1
          );
    },
  },
  components: {
    EditDeleteButtonGroup,
  },
  methods: {
    permissionToString,
    editPermission(permission) {
      this.$router.push({
        name: "permission",
        params: {uid: permission.uid},
      });
    },
    async deletePermission(permission) {
      try {
        await this.$store.dispatch(
            "permissions/deletePermission",
            permission.uid
        );
      } catch (e) {
        await this.$swal.fire({
          icon: "error",
          title:
              "Une erreur est survenue lors de la suppression de la permission",
          text: e.message,
        });
        return;
      }

      await this.$swal.fire({
        icon: "success",
        title: "Permission supprimée",
      });
    },
  },
};
</script>

